import { Inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { country } from '@features/country/data';
import * as Merchant from '@taager-experience-shared/merchant';
import Province = Merchant.com.taager.experience.provinces.domain.entity.Province;
import GetAllProvincesUseCaseAsync = Merchant.com.taager.experience.provinces.domain.interactor.GetAllProvincesUseCaseAsync;

@Injectable({
  providedIn: 'root',
})
export class ProvincesFormValidator {
  private _provinces: Province[] = [];

  constructor(
    @Inject(GetAllProvincesUseCaseAsync)
    private readonly _getAllProvinces: GetAllProvincesUseCaseAsync,
  ) {
    this.getProvinces();
  }

  public validate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !control.value[0]) {
        return null;
      }
      const controlValue = control.value;
      if (this.isLocationValid(controlValue[0].location)) {
        return null;
      }
      return { province: { value: controlValue[0] } };
    };
  }

  private getProvinces(): void {
    this._getAllProvinces
      .execute(country.code)
      .then((provinces) => {
        this._provinces = provinces;
      })
      .catch(() => {
        // no-op
        // Toast is already shown by the order component in case of provinces error
      });
  }

  private isLocationValid(location: string): boolean {
    return this._provinces.find((province) => province.location === location) !== undefined;
  }
}
