/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */
import { CommonModule, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { user } from '@features/user/data';
// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { finalize, map } from 'rxjs/operators';
import {
  ProvinceZoneDistrictFilterModel,
  ProvinceZoneDistrictModel,
  ProvinceZoneFilterModel,
  ProvinceZoneModel,
} from 'src/app/core/domain/provinces.model';
import { GetProvinceZoneDistrictsUseCase } from 'src/app/core/usecases/province/get-province-zone-district-list.usecase';
import { GetProvinceZonesUseCase } from 'src/app/core/usecases/province/get-province-zone-list.usecase';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import { country } from '@features/country/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// @ts-ignore
import * as Merchant from '@taager-experience-shared/merchant';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { CartModel } from 'src/app/core/domain/cart.model';
import { MerchantOrderPreferencesModel } from 'src/app/core/domain/order-model';
import {
  OrderProductListModel,
  ProductPriceOrderModel,
} from 'src/app/core/domain/order/product-price.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetCountryVATByIsoCode3UseCase } from 'src/app/core/usecases/get-country-vat.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CalculateOrderCostUseCase } from 'src/app/core/usecases/order/calculate-order-cost.usecase';
import { GetOrderProductPricesUseCase } from 'src/app/core/usecases/order/get-order-product-prices.usecase';
import { MakeOrderByCartUseCase } from 'src/app/core/usecases/order/make-order-by-cart';
import { OrderPreferenceComponent } from 'src/app/presentation/orders/order-preference/order-preference.component';
import { UpSellingFeedbackComponent } from 'src/app/presentation/orders/up-selling-feedback/up-selling-feedback.component';
import { ENGLISH_LANGUAGE } from '../../constants/country-language-codes-mapping.constants';
import { KUWAIT_PROVINCE_LOCATION } from '../../constants/provinces';
import { HoverStyleDirective } from '../../directives/hover-style.directive';
import { CheckoutProduct } from '../../interfaces/checkout.interface';
import { CurrencyShortNamePipe } from '../../pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../pipes/currency-translate.pipe';
import { featureAttributeAssign } from '../../utilities/feature-attribute-assign.utility';
import { LoaderComponent } from '../loader/loader.component';
import { InputComponent } from './input-control/input.component';
import { OrderProvinceModel } from './models/order-province.model';
import { OrderPresenter } from './presentation/order.presenter';
import { OrderSideEffect } from './presentation/order.side-effects';
import { OrderViewEvents } from './presentation/order.view-events';
import { OrderViewState } from './presentation/order.view-state';
import { ProvincesFormValidator } from './validator/provinces-form-validator';
import GetAllProvincesUseCaseAsync = Merchant.com.taager.experience.provinces.domain.interactor.GetAllProvincesUseCaseAsync;
// eslint-disable-next-line
import { mixPanelEvent } from '@/v3/features/activities/data/mixpanel';
import { InfoAlertComponent } from 'app/components/info-alert/info-alert.component';

declare let gtag: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    HoverStyleDirective,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    AngularMultiSelectModule,
    NgFor,
    RouterLink,
    NgStyle,
    NgClass,
    TranslateModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    UpSellingFeedbackComponent,
    OrderPreferenceComponent,
    CommonModule,
    InfoAlertComponent,
  ],
  providers: [OrderPresenter],
})
export class OrderComponent
  extends BaseComponent<OrderPresenter, OrderViewState, OrderViewEvents, OrderSideEffect>
  implements OnInit, OnDestroy
{
  @Input() products: Array<CheckoutProduct>;

  @Input() product: any;

  @Input() isOrderNow: boolean;

  @Input() orderFromProduct: boolean;

  @Input() cartData: CartModel;

  @Output() submitted: EventEmitter<any> = new EventEmitter();

  @Output() back: EventEmitter<any> = new EventEmitter();

  loading = false;

  shippingCost = 0;

  isShippingCalculated = false;

  orderForm: UntypedFormGroup;

  dropdownClosed = true;

  promotionEntitled = false;

  currency: string;

  public provinces: OrderProvinceModel[];

  public provinceZones: ProvinceZoneModel[];

  public provinceZoneDistricts: ProvinceZoneDistrictModel[];

  public phoneHint: String = '';

  public shouldShowVAT = false;

  public shouldShowDiscountAsSecondProduct = false;

  public loadingProfitAndCOD = false;

  private _languageChangeSubscription: Subscription;

  isEnglishLanguageSelected = false;

  public shouldShowQuantityDiscount = false;

  public math = Math;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  public shouldShowStockDistribution = false;

  public shouldShowUpSellingFeedback = false;

  public shouldShowUpOrderPreference = false;

  public orderPreference: MerchantOrderPreferencesModel;

  public vatOnDiscountedPrice = false;

  public orderPrice: ProductPriceOrderModel;

  public productListEssentials: any[] = [];

  public totalProductItems = 0;

  public totalQuantityDiscountApplied = 0;

  public isInvalidPrice = false;

  public invalidPriceError: string;

  public presenter: OrderPresenter = inject(OrderPresenter);

  constructor(
    private _makeOrderByCartUseCase: MakeOrderByCartUseCase,
    private _calculateOrderCostUseCase: CalculateOrderCostUseCase,
    private readonly _toastService: ToastrService,
    private readonly _translateService: TranslateService,
    private readonly _provincesFormValidator: ProvincesFormValidator,
    @Inject(GetAllProvincesUseCaseAsync)
    private readonly _getAllProvinces: GetAllProvincesUseCaseAsync,
    private multitenancyService: MultitenancyService,
    private getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _getOrderProductPricesUseCase: GetOrderProductPricesUseCase,
    private _getCountryVATByIsoCode3UseCase: GetCountryVATByIsoCode3UseCase,
    private _getProvinceZonesUseCase: GetProvinceZonesUseCase,
    private _getProvinceZoneDistrictsUseCase: GetProvinceZoneDistrictsUseCase,
  ) {
    super();
  }

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });

    this._getCountryPhoneValidation().subscribe({
      next: (validators) => {
        this._createForm(validators);
      },
      error: () => {
        this._createForm();
      },
    });

    this.setupAppliedPrice();
    this._getSelectedLanguage();
    this.getProvinces();
    this.getOrderProductPrice();
    this.getFeatureAttributeList();

    if (this.orderFromProduct) {
      this.orderForm.addControl(
        'quantity',
        new UntypedFormControl(this.products[0].quantity, [Validators.required, Validators.min(1)]),
      );
      const product = this.products[0];
      this.orderForm.addControl(
        'price',
        new UntypedFormControl(product.appliedPrice, [Validators.required]),
      );
      this.orderForm.addControl('productProfit', new UntypedFormControl(product.productProfit));
      this.orderForm.addControl(
        'originalProfit',
        new UntypedFormControl(product.productOriginalProfit),
      );
    }
    this._setupAdditionalFields();
    this.setProductsVariants();

    this.currency = this.multitenancyService.getCurrentCountry().currency.arabicName;
    this.products.forEach((product) => {
      this.totalProductItems += product.quantity;
    });
  }

  public setupAppliedPrice(): void {
    this.products.forEach((product) => {
      const finalPrice = product.newPrice || product.customPrice;
      product.appliedPrice =
        this.orderFromProduct && !this.isOrderNow
          ? product.sale?.productPrice || finalPrice
          : finalPrice;
    });
  }

  /* eslint-disable */
  public getOrderProductPrice(isQuantityUpdated?: boolean, productPriceSet?: number): void {
    this.loadingProfitAndCOD = true;
    this.productListEssentials = [];
    this.products.map((product) => {
      let productItem = {
        productId: product.pid || product.prodId,
        desiredPrice: productPriceSet ? productPriceSet : product.appliedPrice,
        quantity: product.quantity,
      };
      this.productListEssentials.push(productItem);
    });
    const orderDetail: OrderProductListModel = {
      products: this.productListEssentials,
      taagerId: user.id,
      country: country.code,
    };
    this._getOrderProductPricesUseCase
      .execute(orderDetail)
      .pipe(
        finalize(() => {
          if (!isQuantityUpdated) {
            this.loadingProfitAndCOD = false;
          }
        }),
      )
      .subscribe({
        next: (res) => {
          this.orderPrice = res;
          if (isQuantityUpdated) {
            this.setDiscountedPriceAmount();
          }
          if (!this.cartData) {
            this.calculateTotalQuantityDiscountApplied();
          }
          this.validatePriceField();
        },
      });
  }

  public validatePriceField(): void {
    const product = this.orderPrice.products[0];
    const { discount, originalPrice, validity } = product;
    const quantity = this.orderForm.get('quantity')?.value ?? 1;
    const formPriceValue = this.orderForm.get('price')?.value ?? 0;

    let productPrice =
      (quantity > 1 ? discount?.price : discount?.applied ? discount?.price : originalPrice) ?? 0;

    const getInvalidPriceError = (isMaxError: boolean) =>
      isMaxError
        ? `${this.trans('CART.MAX_INVALID_PRICE')}`
        : `${this.trans('CHECKOUT.INVALID_PRICE')}`;

    if (formPriceValue) {
      if (!validity.isValid) {
        this.isInvalidPrice = true;
        this.invalidPriceError = getInvalidPriceError(validity.details.includes('maximum'));
      } else if (formPriceValue < productPrice) {
        this.isInvalidPrice = true;
        this.invalidPriceError = `${this.trans('CHECKOUT.INVALID_PRICE')}`;
      } else {
        this.isInvalidPrice = false;
      }
    }
    this.loadingProfitAndCOD = false;
  }

  public setDiscountedPriceAmount(): void {
    const product = this.orderPrice.products[0];
    const quantity = this.orderForm.get('quantity')!.value;
    const productPrice = quantity > 1 ? product.discount!.price : product.finalPricePerItem;
    this.orderForm.get('price')!.setValue(productPrice);
    this.getOrderProductPrice(false, productPrice);
  }
  /* eslint-enable */

  public calculateTotalQuantityDiscountApplied(): void {
    this.totalQuantityDiscountApplied = 0;
    const productQuantity = this.orderForm.get('quantity')!.value;
    const product = this.orderPrice?.products[0];
    if (productQuantity > 1 && product.discount?.discountList?.includes('AdditionalQuantity')) {
      const originalPricePerItem =
        this.products[0].sale?.productPrice || product.originalPricePerItem;
      const discountedPrice = product.discount?.price;
      const appliedQuantity = this.orderForm.get('quantity')!.value;
      const qtyDiscount = (originalPricePerItem - discountedPrice) * appliedQuantity;
      this.totalQuantityDiscountApplied = qtyDiscount > 0 ? qtyDiscount : 0;
    }
  }

  public orderPreferencesChanged(orderPreferenceProperties: MerchantOrderPreferencesModel): void {
    this.orderPreference = orderPreferenceProperties;
  }

  public getFeatureAttributeList(): void {
    /* eslint-disable */
    const quantityDiscount = this._getFeatureAttributeUseCase.execute(
      FEATURE_FLAGS.QUANTITY_DISCOUNT,
    );
    const vatDiscount = this._getFeatureAttributeUseCase.execute(
      FEATURE_FLAGS.VAT_ON_DISCOUNTED_PRICE,
    );
    const stockDistribution = this._getFeatureAttributeUseCase.execute(
      FEATURE_FLAGS.STOCK_DISTRIBUTION,
    );
    const secondProductDiscount = this.getFeatureFlagUsecase.execute(
      FEATURE_FLAGS.DISCOUNTED_AS_SECOND_PRODUCT,
    );
    const upsellingFeedback = this.getFeatureFlagUsecase.execute(FEATURE_FLAGS.UP_SELLING_FEEDBACK);
    const upOrderPreference = this.getFeatureFlagUsecase.execute(FEATURE_FLAGS.ORDER_PREFERENCE);
    const countryVAT = this._getCountryVATByIsoCode3UseCase.execute(
      this.multitenancyService.getCurrentCountry().isoCode3,
    );

    const userId = user.id;
    const userCountry = this.multitenancyService.getCurrentCountry().isoCode3;

    combineLatest({
      quantityDiscount,
      vatDiscount,
      stockDistribution,
      secondProductDiscount,
      upsellingFeedback,
      upOrderPreference,
      countryVAT,
    }).subscribe({
      next: ({
        quantityDiscount,
        vatDiscount,
        stockDistribution,
        secondProductDiscount,
        upsellingFeedback,
        upOrderPreference,
        countryVAT,
      }) => {
        this.shouldShowQuantityDiscount = featureAttributeAssign(
          quantityDiscount,
          userId,
          userCountry,
        );

        this.vatOnDiscountedPrice = featureAttributeAssign(vatDiscount, userId, userCountry);

        this.shouldShowStockDistribution = featureAttributeAssign(
          stockDistribution,
          userId,
          userCountry,
        );

        this.shouldShowDiscountAsSecondProduct = featureAttributeAssign(
          secondProductDiscount,
          userId,
          userCountry,
        );

        this.shouldShowUpSellingFeedback = upsellingFeedback;
        this.shouldShowUpOrderPreference = upOrderPreference;
        this.shouldShowVAT = countryVAT > 0;
      },
    });
    /* eslint-enable */
  }

  private getProvinces(): void {
    this._getAllProvinces
      .execute(country.code)
      .then((provinces) => {
        this.provinces = provinces.map((province) => ({
          id: province.id,
          location: province.location,
          provinceGroupLabel: `- ${province.branch} -`,
        }));
      })
      .catch(() => {
        this._toastService.error(
          this._translateService.instant('CART.BULK_ORDERS.ERRORS.CANNOT_VERIFY_PROVINCES'),
        );
      });
  }

  updateQuantity(): void {
    this.loadingProfitAndCOD = true;
    this.products[0].quantity = this.orderForm.get('quantity')!.value;
    this.getOrderProductPrice(true);
  }

  calculateProfitAndCOD(): void {
    this.loadingProfitAndCOD = true;
    this.products[0].customPrice = this.orderForm.get('price')!.value;
    this.getOrderProductPrice(false, this.products[0].customPrice);
  }

  setProductsVariants(): void {
    this.products = this.products.map((product) => {
      const productColorHex = product.attributes.filter(
        (attribute) => attribute.type === 'color',
      )[0]?.value;
      const productColorName = this.isEnglishLanguageSelected
        ? variants.getColorByHexCode(productColorHex!).englishName
        : variants.getColorByHexCode(productColorHex!).arabicName;
      return {
        ...product,
        productColorHex,
        productColor: productColorHex ? productColorName : null,
        productSize: product.attributes.filter((attribute) => attribute.type === 'size')[0]?.value,
      };
    });
  }

  get province(): AbstractControl {
    return this.orderForm.get('province')!;
  }

  get isInvalid(): boolean {
    return this.province && this.province.invalid && !this.province.pristine;
  }

  private _createForm(validators?: any): void {
    this.orderForm = new UntypedFormGroup({
      receiverName: new UntypedFormControl('', [Validators.required]),
      province: new UntypedFormControl(null, [
        Validators.required,
        this._provincesFormValidator.validate(),
      ]),
      streetName: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', validators || [Validators.pattern('^[0-9]*$')]),
      phoneNumber2: new UntypedFormControl(
        '',
        validators.filter((validator: any) => validator !== Validators.required) || [
          Validators.pattern('^[0-9]*$'),
        ],
      ),
      notes: new UntypedFormControl(''),
      orderSource: new UntypedFormGroup({
        pageName: new UntypedFormControl(''),
        pageUrl: new UntypedFormControl(''),
      }),
    });
  }

  private regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  private _getCountryPhoneValidation(): Observable<ValidatorFn[]> {
    const defaultValidators = [Validators.required, Validators.pattern('^[0-9]*$')];
    return this.getFeatureFlagUsecase.execute(FEATURE_FLAGS.ORDERS_PHONE_VALIDATIONS_FLAG).pipe(
      map((flag) => {
        if (flag) {
          this.phoneHint = country.phoneNumberHint;

          if (country.phoneRegex) {
            return [
              Validators.required,
              this.regexValidator(new RegExp(country.phoneRegex), { invalidPhoneNumber: true }),
            ];
          }

          return defaultValidators;
        }
        const validators = country.is('EGY')
          ? [
              Validators.required,
              Validators.minLength(11),
              Validators.maxLength(11),
              Validators.pattern('^(01)[0-9]*$'),
            ]
          : defaultValidators;
        return validators;
      }),
    );
  }

  onSubmit(): void {
    if (this.orderForm.invalid) {
      this.setUpMixpanel('Order_Completion', false);
      this.markFormGroupTouched(this.orderForm);
    } else {
      this.orderCart();
    }
  }

  backToCart(): void {
    this.back.emit();
  }

  onProvinceChange(): void {
    if (this.viewState.shouldShowDetailAddressFields) {
      this._getProvinceZones();
    }
    this._adjustPhoneNumberValidation();
    this.calculateShippingCost();
  }

  onZoneChange(): void {
    this._getProvinceZoneDistricts();
  }

  private _getProvinceZones(): void {
    const zoneFilter: ProvinceZoneFilterModel = {
      country: this.multitenancyService.getCurrentCountry().isoCode3,
      provinceId: this.orderForm.get('province')?.value[0].location,
    };
    this._getProvinceZonesUseCase.execute(zoneFilter).subscribe({
      next: (zoneList) => {
        this.provinceZones = zoneList;
      },
    });
  }

  private _getProvinceZoneDistricts(): void {
    const districtFilter: ProvinceZoneDistrictFilterModel = {
      country: this.multitenancyService.getCurrentCountry().isoCode3,
      provinceId: this.orderForm.get('province')?.value[0].location,
      zoneId: this.orderForm.get('zone')?.value[0]?.zoneId,
    };
    this._getProvinceZoneDistrictsUseCase.execute(districtFilter).subscribe({
      next: (districtList) => {
        this.provinceZoneDistricts = districtList;
      },
    });
  }

  private _adjustPhoneNumberValidation(): void {
    const countryIsoCode3 = this.multitenancyService.getCurrentCountry().isoCode3;
    this.getFeatureFlagUsecase.execute(FEATURE_FLAGS.ALLOW_KUWAIT_PHONE_NUMBERS).subscribe({
      next: (flag) => {
        if (flag && country.is('UAE')) {
          const selectedProvince = this.orderForm.get('province')!.value[0];
          if (selectedProvince?.location === KUWAIT_PROVINCE_LOCATION) {
            this._updatePhoneNumberValidationToKuwaitNumbers();
          } else {
            this._updatePhoneNumberValidationAccordingToCountry();
          }
        }
      },
    });
  }

  private _updatePhoneNumberValidationToKuwaitNumbers(): void {
    const phoneNumberForm = this.orderForm.get('phoneNumber');
    const phoneRegex = /^965[569]\d{7}$/;
    const validators = [
      Validators.required,
      this.regexValidator(phoneRegex, { invalidPhoneNumber: true }),
    ];
    phoneNumberForm!.setValidators(validators);
    this.phoneHint = '96551234567';
    phoneNumberForm!.updateValueAndValidity();
  }

  private _updatePhoneNumberValidationAccordingToCountry(): void {
    const phoneNumberForm = this.orderForm.get('phoneNumber');
    this._getCountryPhoneValidation().subscribe({
      next: (phoneNumberValidation) => {
        phoneNumberForm!.setValidators(phoneNumberValidation);
        phoneNumberForm!.updateValueAndValidity();
      },
    });
  }

  calculateShippingCost(): void {
    const orderData = {
      province: (this.province.value && this.province.value[0]?.location) as string,
      products: this.products.map((product) => product.id),
      productQuantities: this.products.map((product) => product.quantity),
      productPrices: this.products.map((product) => product.productPrice || product.newPrice),
      productIds: this.products.map((product) => product.pid || product.prodId),
      orderLines: this.products.map((product) => ({
        productId: product.pid || product.prodId,
        quantity: product.quantity,
        totalPrice: product.productPrice || product.newPrice,
      })),
    };
    this.isShippingCalculated = false;
    this.promotionEntitled = false;
    this.shippingCost = 0;
    if (orderData.province) {
      this.loadingProfitAndCOD = true;
      this._calculateOrderCostUseCase
        .execute(orderData)
        .pipe(
          finalize(() => {
            this.loadingProfitAndCOD = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.shippingCost = res.shipping.discountedRate;
            if (this.shippingCost === 0) {
              this.promotionEntitled = true;
            }
            this.isShippingCalculated = true;
          },
          error: () => {
            this.shippingCost = 0;
            this.isShippingCalculated = false;
          },
        });
    }
  }

  private setUpMixpanel(event_name: string, status: any): void {
    const errors: any = [];
    const index = 0;
    if (status) {
      this._logMixpanelEventUseCase.execute({
        eventName: event_name,
        payload: {
          'Number of Products': this.products.length,
          'Total Products': this.totalProductItems,
          'Payment Amount': this.orderPrice.totalPrice + this.shippingCost,
          Status: 'Success',
        },
      });
    } else {
      errors[0] = {
        field: 'notes',
        status: this.orderForm.controls.notes.status,
      };
      errors[1] = {
        field: 'Phone Number',
        status: this.orderForm.controls.phoneNumber.status,
      };
      errors[2] = {
        field: 'Phone Number2',
        status: this.orderForm.controls.phoneNumber2.status,
      };
      errors[3] = {
        field: 'Province',
        status: this.orderForm.controls.province.status,
      };
      errors[4] = {
        field: 'Reiceiver Name',
        status: this.orderForm.controls.receiverName.status,
      };
      errors[5] = {
        field: 'Address',
        status: this.orderForm.controls.streetName.status,
      };
      this._logMixpanelEventUseCase.execute({
        eventName: event_name,
        payload: {
          'Payment Amount': this.orderPrice.totalPrice + this.shippingCost,
          Status: 'Unsuccessful',
          Errors: errors,
        },
      });
    }
  }

  private setUpNewMixpanel(
    event_name: string,
    status: any,
    errorMessage?: string,
    errorObject?: any,
  ): void {
    let piecesCount = 0;
    this.products.forEach((product) => {
      piecesCount += product.quantity;
    });
    let mixpanelObj: any = {
      Language: 'ar',
      'Pieces Count': piecesCount,
      'Is Has Variants':
        this.products.filter((product) => product.attributes && product.attributes.length).length >
        0,
      Products: this.products.map((product) => ({
        'Product id': product.id,
        'Product name': product.productName,
        'Product category': product.Category,
      })),
      'Total Order Price': this.orderPrice.totalPrice,
      'Total Profit Amount': this.orderPrice.totalProfit,
      'Client Full Name': this.orderForm.value.receiverName,
      'Client Mobile Number': this.orderForm.value.phoneNumber,
      'Client Alternative Number': this.orderForm.value.phoneNumber2,
      'Client Governate': this.orderForm.value.province,
      'Client Address': this.orderForm.value.streetName,
      'Merchant Facebook Page': this.orderForm.value.orderSource.pageName,
      'Merchant Page Link': this.orderForm.value.orderSource.pageUrl,
      'Merchant Notes': this.orderForm.value.notes,
      'Order Request Status': status ? 'Succeeded' : 'Failed',
    };
    if (!status) {
      mixpanelObj = {
        ...mixpanelObj,
        errorMessage,
        errorObject,
      };
    }
    this._logMixpanelEventUseCase.execute({
      eventName: event_name,
      payload: mixpanelObj,
    });
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup): void {
    (Object as any).values(formGroup.controls).forEach((control: UntypedFormControl) => {
      control.markAsTouched();
      control.markAsDirty();
    });
  }

  private orderCart(): void {
    this.setUpMixpanel('Order_Completion', true);
    const zoneField = this.orderForm.get('zone');
    const districtField = this.orderForm.get('district');

    const order = this.orderForm.value;
    const orderQuantity = order.quantity;
    const orderPrice = order.price;

    order.products = [];
    order.productQuantities = [];
    order.productPrices = [];
    order.productIds = [];
    order.phoneNumber = order.phoneNumber.trim();
    order.phoneNumber2 = order.phoneNumber2.trim();
    this.orderPrice.products.forEach((product, index) => {
      order.products.push(this.products[index].id);
      order.productQuantities.push(this.productListEssentials[index].quantity);
      order.productPrices.push(product.finalPrice);
      order.productIds.push(product.productId);
    });

    order.orderProfit = this.orderPrice.totalProfit;
    order.cashOnDelivery = this.orderPrice.totalPrice + this.shippingCost;
    order.province = this.province.value && this.province.value[0]?.location;

    if (this.viewState.shouldShowDetailAddressFields && zoneField && districtField) {
      order.zone = zoneField.value ? zoneField.value[0].zoneName.arabic : undefined;
      order.district = districtField.value ? districtField.value[0].districtName.arabic : undefined;
    }

    order.merchantOrderPreferences = this.orderPreference;
    mixPanelEvent('Order_Summary_page_place_order_initiate', order);
    this._makeOrderByCartUseCase
      .execute(order)
      .pipe(
        finalize(() => {
          gtag('event', 'order', {
            event_category: 'ecommerce',
            event_label: `phoneNum:${user.phoneNumber}, TagerID:${user.id}`,
          });
          this.loading = false;
          window.scrollTo(0, 0);
        }),
      )
      .subscribe({
        next: (res) => {
          this.submitted.emit({
            status: true,
            orderID: res.order.orderID,
            orderQuantity: parseInt(orderQuantity, 10),
            orderPrice: parseInt(orderPrice, 10),
          });
          this.setUpNewMixpanel('Order_summary_page_place_order_response', true);
        },
        error: ({ error }) => {
          this.setUpNewMixpanel('Order_summary_page_place_order_response', false, error.msg, error);
          this.submitted.emit({
            status: false,
            orderID: '',
            errorMessage: error.msg,
            orderQuantity: parseInt(orderQuantity, 10),
            orderPrice: parseInt(orderPrice, 10),
          });
        },
      });
  }

  private _getSelectedLanguage(): void {
    this.isEnglishLanguageSelected = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: ({ lang }: { lang: string }) => {
        this.isEnglishLanguageSelected = lang === ENGLISH_LANGUAGE;
        this.setProductsVariants();
      },
    });
  }

  private _setupAdditionalFields(): void {
    if (this.viewState.shouldShowDetailAddressFields) {
      this.orderForm.addControl('zone', new UntypedFormControl());
      this.orderForm.addControl('district', new UntypedFormControl());
    }
  }

  protected onDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
