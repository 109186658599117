import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-information-notice',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './information-notice.component.html',
  styleUrls: ['./information-notice.component.scss'],
})
export class InformationNoticeComponent extends LocalizedComponent {
  @Input() text: string;
}
