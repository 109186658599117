import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { country } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-input',
  styleUrls: ['./input.component.scss'],
  templateUrl: './input.component.html',
  standalone: true,
  imports: [NgIf, FormsModule, NgClass, ReactiveFormsModule, TranslateModule],
})
export class InputComponent implements OnInit {
  _value: any;

  get value() {
    return this._value;
  }

  set value(val: any) {
    this._value = val;
    this.propagateChange(val);
  }

  @Input() label: string;

  @Input() requiredField: boolean;

  @Input() readonlyField = false;

  @Input() type: string;

  @Input() id: string;

  @Input() name: string;

  @Input() placeholder: string;

  @Input() formControl: UntypedFormControl;

  @Input() min: number;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputFieldElementRef', { static: true })
  private _inputFieldElementRef: ElementRef<HTMLInputElement>;

  isAPhoneInputField: boolean;

  public isUAESelected = false;

  ngOnInit(): void {
    this.isAPhoneInputField = this.name === 'phoneNumber';
    this._listenForScrollEventOnNumberField();
    this.isUAESelected = country.is('UAE');
  }

  private _listenForScrollEventOnNumberField(): void {
    if (this.type === 'number') {
      this._inputFieldElementRef.nativeElement.addEventListener('wheel', (ev) => {
        ev.preventDefault();
        const currentScrollY = document.documentElement.scrollTop || document.body.scrollTop;
        window.scroll({ top: currentScrollY + ev.deltaY, behavior: 'smooth' });
      });
    }
  }

  get isInvalid() {
    return this.formControl && this.formControl.invalid && !this.formControl.pristine;
  }

  propagateChange = (_: any) => {};

  writeValue(obj: any): void {
    this.value = obj;
    this.propagateChange(obj);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    fn();
  }

  onBlur(event: any): void {
    this.blur.emit(event);
  }

  setDisabledState?(isDisabled: boolean): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDisabled;
  }
}
