import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { finalize, Subscription } from 'rxjs';
import {
  QuestionnaireAnswerModel,
  QuestionnaireModel,
} from 'src/app/core/domain/questionnaire.model';
import { GetQuestionnaireQuestionsUseCase } from 'src/app/core/usecases/get-questionnaire-questions.usecase';
import { SubmitQuestionnaireAnswersUseCase } from 'src/app/core/usecases/submit-questionnaire-answers.usecase';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { MERCHANT_INSIGHTS_URL, UP_SELLABLE_FEEDBACK_NAME } from '../../shared/constants';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from '../../shared/services/translate.service';

@Component({
  selector: 'app-up-selling-feedback',
  standalone: true,
  imports: [
    TranslateModule,
    NgFor,
    NgIf,
    LoaderComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './up-selling-feedback.component.html',
  styleUrls: ['./up-selling-feedback.component.scss'],
})
export class UpSellingFeedbackComponent implements OnInit {
  public survey: QuestionnaireModel;

  public question: string;

  public answers: QuestionnaireAnswerModel[];

  public isUserEligibleForSurvey = false;

  public isLTR = false;

  public isLoading = false;

  public showSubmitButton = false;

  public selectedAnswer: string | null;

  public placeholder: string;

  public isSurveySubmitted = false;

  public answerId: string;

  public surveyForm = new FormGroup({
    comment: new FormControl(''),
  });

  public assetsPath = '../../../../assets/img/';

  private _languageChangeSubscription: Subscription;

  constructor(
    private _getQuestionnaireQuestionsUseCase: GetQuestionnaireQuestionsUseCase,
    private _translateService: TranslateService,
    private _siteTranslateService: SiteTranslateService,
    private _submitQuestionnaireAnswersUseCase: SubmitQuestionnaireAnswersUseCase,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.isLTR = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.getFeedbackData();
    this.setQuestionnairePropertiesOnLanguageChange();
  }

  getFeedbackData() {
    this.isLoading = true;
    this._getQuestionnaireQuestionsUseCase
      .execute(UP_SELLABLE_FEEDBACK_NAME)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (feedback) => {
          this.isUserEligibleForSurvey = true;
          this.survey = feedback;
          this.setQuestionnaireBasedOnSelectedLanguage();
        },
        error: () => {
          this.isUserEligibleForSurvey = false;
        },
      });
  }

  setQuestionnairePropertiesOnLanguageChange(): void {
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isLTR = event.lang === ENGLISH_LANGUAGE;
        this.setQuestionnaireBasedOnSelectedLanguage();
      },
    });
  }

  setQuestionnaireBasedOnSelectedLanguage() {
    [this.question] = this.survey.questions.map((survey) =>
      this.isLTR ? survey.textEnglish : survey.textArabic,
    );
    this.answers = this.survey.questions[0].answers;
  }

  goToInsightsPage() {
    this._router.navigate([MERCHANT_INSIGHTS_URL]);
  }

  onCancelClicked() {
    this.showSubmitButton = false;
    this.selectedAnswer = null;
    this.surveyForm.get('comment')!.reset();
  }

  onAnswerClicked(answer: QuestionnaireAnswerModel, index: number) {
    this.showSubmitButton = true;
    this.selectedAnswer = this.isLTR ? answer.textEnglish : answer.textArabic;
    this.answerId = answer.id;
    if (index === 1) {
      this.placeholder = 'UP_SELLING_FEEDBACK.REQUIRED';
      this.surveyForm.get('comment')!.setValidators([Validators.required]);
      this.surveyForm.get('comment')!.updateValueAndValidity();
    } else {
      this.placeholder = 'UP_SELLING_FEEDBACK.OPTIONAL';
      this.surveyForm.get('comment')!.setValidators([]);
      this.surveyForm.get('comment')!.updateValueAndValidity();
    }
  }

  onSubmitSurvey() {
    const submittedAnswer = {
      answers: [
        {
          questionId: this.survey.questions[0].id,
          answerIds: [this.answerId],
          comment: this.surveyForm.value.comment!,
        },
      ],
    };
    this.isLoading = true;
    this._submitQuestionnaireAnswersUseCase
      .execute({
        questionnaireName: UP_SELLABLE_FEEDBACK_NAME,
        data: submittedAnswer,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.isSurveySubmitted = true;
          this.isUserEligibleForSurvey = false;
        },
        error: (err) => {},
      });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
