<loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
<form [formGroup]="surveyForm">
  <div class="up-selling-feedback-container" *ngIf="!isLoading">
    <p class="body1--bold content-main-color">{{ 'UP_SELLING_FEEDBACK.HEADER' | translate }}</p>
    <p class="body2--regular content-medium-color">
      {{ 'UP_SELLING_FEEDBACK.DESCRIPTION' | translate }}
    </p>
    <button
      class="feedback-buttons feedback-buttons__show caption1--bold"
      (click)="goToInsightsPage()"
    >
      {{ 'UP_SELLING_FEEDBACK.SHOW_BTN' | translate }}
      <img src="{{ assetsPath }}{{ isLTR ? 'right-icon.svg' : 'left-icon.svg' }}" />
    </button>
    <div *ngIf="!isSurveySubmitted && isUserEligibleForSurvey">
      <div class="up-selling-feedback-container__survey">
        <p class="caption1--bold content-main-color">{{ question }}</p>
        <div class="up-selling-feedback-container__survey__answers">
          <p
            class="caption1--bold up-selling-feedback-container__survey__answers__text"
            *ngFor="let answer of answers; let index = index"
            (click)="onAnswerClicked(answer, index)"
            [ngClass]="{
              'selected-answer': isLTR
                ? answer.textEnglish === selectedAnswer
                : answer.textArabic === selectedAnswer
            }"
          >
            {{ isLTR ? answer.textEnglish : answer.textArabic }}
          </p>
        </div>
      </div>
      <div *ngIf="showSubmitButton">
        <input
          formControlName="comment"
          class="reason-input"
          placeholder="{{ placeholder | translate }}"
        />
        <div>
          <button
            class="feedback-buttons feedback-buttons__submit body2--bold"
            (click)="onSubmitSurvey()"
            [disabled]="surveyForm.invalid"
          >
            {{ 'UP_SELLING_FEEDBACK.SUBMIT_BTN' | translate }}
          </button>
          <button
            class="feedback-buttons feedback-buttons__cancel body2--bold"
            (click)="onCancelClicked()"
          >
            {{ 'UP_SELLING_FEEDBACK.CANCEL_BTN' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="submitted-survey" *ngIf="!isUserEligibleForSurvey">
      <img src="{{ assetsPath + 'check.svg' }}" />
      <p class="caption1--bold content-main-color">
        {{ 'UP_SELLING_FEEDBACK.SUBMITTED' | translate }}
      </p>
    </div>
  </div>
</form>
