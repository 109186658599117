import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { CART_BANNER_KEY, CATALOG_BANNER_KEY } from '../../shared/constants/quantity-discount';
import { LocalStorageService } from '../../shared/services/local-storage.service';

@Component({
  selector: 'app-quantity-discount-banner',
  templateUrl: './quantity-discount-banner.component.html',
  styleUrls: ['./quantity-discount-banner.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink],
})
export class QuantityDiscountBannerComponent extends LocalizedComponent {
  constructor(private _localStorageService: LocalStorageService) {
    super();
  }

  @Input() shouldShowDetailLine = false;

  @Output() updateBannerVisibility: EventEmitter<boolean> = new EventEmitter();

  assetsPath = 'assets/img/';

  public hideBanner(): void {
    this._localStorageService.setStorage(
      this.shouldShowDetailLine ? CATALOG_BANNER_KEY : CART_BANNER_KEY,
      'false',
    );
    this.updateBannerVisibility.emit(true);
  }
}
