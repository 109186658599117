<div class="quantity-discount">
  <img [src]="assetsPath + 'package-icon.svg'" alt="package-icon" />
  <p class="quantity-discount__text body1--bold">
    <span class="yellow-text">
      {{ trans('CART.PRODUCT_QUANTITY_DISCOUNT.BANNER_TEXT_1') }}
    </span>
    {{ trans('CART.PRODUCT_QUANTITY_DISCOUNT.BANNER_TEXT_2') }}
    <span *ngIf="shouldShowDetailLine">
      {{ trans('CART.PRODUCT_QUANTITY_DISCOUNT.BANNER_TEXT_3') }}
    </span>
  </p>
  <button class="btn banner-btn body2--bold" (click)="hideBanner()">
    {{ trans('CART.PRODUCT_QUANTITY_DISCOUNT.OKAY_BUTTON') }}
  </button>
</div>
