import { AdditionalQuantityModel } from './products/product-detail.model';

export interface VariantGroupModel {
  _id?: string;
  variants: VariantModel[];
  name: string;
  commercialCategoryIds: string[];
  primaryVariant?: VariantModel;
  visibleToSellers: string[];
  attributeSets: AttributeSetModel[];
  createdAt?: string;
  isExpired?: boolean;
  updatedAt?: string;
  __v?: number;
}

export interface VariantModel {
  productName: string;
  productPrice: number;
  prodPurchasePrice: number;
  productProfit: number;
  productQuantity: number;
  productDescription: string;
  prodID: string;
  Category: string;
  productPicture: string;
  extraImage1: string;
  extraImage2: string;
  extraImage3: string;
  extraImage4: string;
  extraImage5: string;
  extraImage6: string;
  productAvailability: ProductAvailability;
  orderCount: number;
  isPrimary: boolean;
  attributes: AttributeValueModel[];
  _id?: string;
  categoryId?: string;
  specifications?: string;
  productWeight?: number;
  howToUse?: string;
  isExpired?: boolean;
  isExternalRetailer?: boolean;
  visibleToSellers?: string[];
  additionalMedia?: string[];
  embeddedVideos?: string[];
  sellerName: string;
  country: string;
  sale?: VariantSalesModel;
  discountedAsSecondInPlacement?: VariantDiscountedAsSecondModel;
  customAdditionalQuantityDiscount?: AdditionalQuantityModel;
  productMaxPrice: number;
}

export interface AttributeModel {
  name?: string;
}

export interface AttributeValueModel {
  type?: string;
  value?: string;
}

export interface AttributeSetModel {
  type?: string;
  category?: string;
  attributes?: AttributeModel[];
}

export enum ProductAvailability {
  available = 'available',
  not_available = 'not_available',
  available_with_high_qty = 'available_with_high_qty',
  available_with_low_qty = 'available_with_low_qty',
  available_for_preorder = 'available_for_preorder',
}

export interface VariantSalesModel {
  productPrice?: number;
  productProfit?: number;
  saleStartsAt?: string;
  saleExpiresAt?: string;
  createdAt?: string;
}

export interface VariantDiscountedAsSecondModel {
  discountedPrice?: number;
  enabled?: boolean;
  merchantProfit?: number;
}

export interface ProductReviewModel {
  content?: string;
  reasons?: string[];
  productRating?: number;
  priceRating?: number;
}
