import { NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ENGLISH_LANGUAGE } from '../../constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from '../../services/translate.service';

@Component({
  selector: 'app-selected-country-tooltip',
  templateUrl: './selected-country-tooltip.component.html',
  styleUrls: ['./selected-country-tooltip.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class SelectedCountryTooltipComponent implements OnInit, OnDestroy {
  selectedCountry = country;

  selectedCountryName: string;

  tooltipText: string;

  isEnglishLanguage: boolean;

  languageChangeSubscription: Subscription;

  constructor(
    private router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
  ) {
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
        this._setSelectedCountryBasedOnLanguage();
      },
    });
  }

  ngOnInit(): void {
    this._setSelectedCountryBasedOnLanguage();
    this.tooltipText = this.getTooltipText();
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription.unsubscribe();
  }

  getTooltipText(): string {
    switch (this.router.url) {
      case this._appURLs.CATALOG_URL:
        return 'CATALOG_PAGE.CATALOG_VIEW_TITLE';
      case this._appURLs.CART_URL:
      case this._appURLs.CART_V2_URL:
        return 'CART.SELECTED_COUNTRY_TOOLTIP';
      default:
        return 'SELECTED_COUNTRY_TOOLTIP_GENERIC_PAGE';
    }
  }

  _setSelectedCountryBasedOnLanguage(): void {
    this.selectedCountryName = country.name;
  }
}
