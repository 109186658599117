<div class="selected-country-tooltip" *ngIf="selectedCountry">
  <img
    loading="lazy"
    class="selected-country-tooltip__icon"
    [src]="selectedCountry.flag"
    alt="{{ selectedCountry.shortCode }}"
  />
  <p class="selected-country-tooltip__text caption1--bold">
    {{ tooltipText | translate : { selectedCountryName } }}
  </p>
</div>
