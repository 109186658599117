import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import { WEB_ADDRESS_AUTOCONFIRMATION } from 'app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ADDRESS_AUTOCONFIRMATION_USER_FEATURE } from '../../../constants';
import { OrderSideEffect } from './order.side-effects';
import { OrderViewEvents } from './order.view-events';
import { OrderViewState } from './order.view-state';
// eslint-disable-next-line
import { user } from '@features/user/data';
// eslint-disable-next-line
import { country } from '@features/country/data';

@Injectable()
export class OrderPresenter extends BasePresenter<
  OrderViewState,
  OrderViewEvents,
  OrderSideEffect
> {
  constructor(
    private _translateService: TranslateService,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    super();
  }

  protected defaultViewState(): OrderViewState {
    return {
      shouldShowDetailAddressFields: false,
    };
  }

  protected onViewEvent(event: OrderViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.setupCommonDropDownSettings();
        let isUserAttributeEnabled = false;
        let isUserFeatureEnabled = false;
        this._getFeatureAttributeUseCase.execute(WEB_ADDRESS_AUTOCONFIRMATION).subscribe({
          next: (attribute) => {
            isUserAttributeEnabled = featureAttributeAssign(attribute, user.id, country.code);
          },
        });
        isUserFeatureEnabled = this._checkUserFeatureExistsUseCase.execute(
          ADDRESS_AUTOCONFIRMATION_USER_FEATURE,
        );
        const shouldShowDetailAddressFields = isUserAttributeEnabled && isUserFeatureEnabled;

        this.updateViewState({
          ...this.viewState,
          shouldShowDetailAddressFields,
        });
      }
    }
  }

  setupCommonDropDownSettings() {
    const zoneTranslatedName = this._translateService.instant('CHECKOUT.SELECT_ZONE');
    const districtTranslatedName = this._translateService.instant('CHECKOUT.SELECT_DISTRICT');
    const provinceTranslatedName = this._translateService.instant('CHECKOUT.SELECT_PROVINCE');
    const searchKey = this._translateService.instant('CHECKOUT.SEARCH');

    const commonSettings = {
      enableSearchFilter: true,
      searchPlaceholderText: searchKey,
      singleSelection: true,
      enableCheckAll: false,
      maxHeight: 280,
      lazyLoading: false,
      showCheckbox: false,
      position: 'bottom',
      autoPosition: false,
      escapeToClose: false,
    };

    this.viewState.districtDropdownSettings = {
      text: districtTranslatedName,
      ...commonSettings,
    };
    this.viewState.zoneDropdownSettings = {
      text: zoneTranslatedName,
      ...commonSettings,
    };
    this.viewState.provinceDropdownSettings = {
      text: provinceTranslatedName,
      ...commonSettings,
    };
    this.updateViewState({
      ...this.viewState,
    });
  }
}
