<div class="form-group">
  <label>{{ label }} <span *ngIf="requiredField"></span></label>
  <input
    #inputFieldElementRef
    class="form-control"
    [ngClass]="{ 'input-error': isInvalid }"
    [type]="type"
    [id]="id"
    [formControl]="formControl"
    (blur)="onBlur($event)"
    [name]="name"
    [readonly]="readonlyField"
    [min]="min"
    [placeholder]="placeholder"
  />
  <div *ngIf="isInvalid">
    <div *ngIf="formControl.errors!.required">
      <p class="input-error">{{ 'ERRORS.FIELD_REQUIRED' | translate }}</p>
    </div>
    <div *ngIf="formControl.errors!.pattern">
      <p class="input-error">{{ 'ERRORS.FIELD_INCORRECT' | translate }}</p>
    </div>
    <div
      *ngIf="isAPhoneInputField && (formControl.errors!.minlength || formControl.errors!.maxlength)"
    >
      <p class="input-error" *ngIf="!isUAESelected">
        {{ 'ERRORS.PHONE_NUMBER_LIMIT' | translate }}
      </p>
      <p class="input-error" *ngIf="isUAESelected">
        {{ 'ERRORS.UAE_PHONE_NUMBER_LIMIT' | translate }}
      </p>
    </div>
    <div *ngIf="!isAPhoneInputField && formControl.errors!.minlength">
      <p class="input-error">Value is too short</p>
      <div *ngIf="!isAPhoneInputField && formControl.errors!.maxlength">
        <p class="input-error">Value is too large</p>
      </div>
      <div *ngIf="formControl.errors!.min">
        <p class="input-error">{{ 'ERRORS.VALUE_LESS' | translate }}</p>
      </div>
      <div *ngIf="formControl.errors!.provinceName && !formControl.errors!.required">
        <p class="input-error">{{ 'ERRORS.CORRECT_PROVINCE' | translate }}</p>
      </div>
    </div>
  </div>
</div>
